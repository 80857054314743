import { ApplicationController } from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['form', 'status', 'targetIds']
  static values = { targetIds: { type: Array, default: [] } }

  submit() {
    if (!this.targetIdsValue.length || this.statusTarget.value === '') {
      return
    }
    this.formTarget.submit()
  }

  updateTargetIds(event) {
    this.targetIdsValue = event.detail.values
    this.targetIdsTarget.value = this.targetIdsValue.join(',')
  }
}
