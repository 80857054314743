import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['message', 'text']
  static classes = []
  static values = {
    text: String,
  }

  copyText() {
    navigator.clipboard.writeText(this.textValue)
    let originalText = this.messageTarget.innerHTML
    this.messageTarget.innerHTML = 'コピーしました'
    setTimeout(() => {
      this.messageTarget.innerHTML = originalText
    }, 1200)
  }

  copyInnerText() {
    navigator.clipboard.writeText(this.textTarget.innerText)
    let originalText = this.messageTarget.innerHTML
    this.messageTarget.innerHTML = 'コピーしました'
    setTimeout(() => {
      this.messageTarget.innerHTML = originalText
    }, 1200)
  }
}
