import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['rejectButton']

  rejectDueToManyWarnings() {
    this.dispatch('rejectDueToManyWarnings')
  }

  clickRejectButton() {
    this.rejectButtonTarget.click()
  }
}
