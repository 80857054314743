import { Controller } from 'stimulus'
import { fetchOgpData } from '../../../utils/ogp'

export default class extends Controller {
  static targets = ['url', 'title', 'image', 'imageBase64']

  async fetch() {
    const url = this.urlTarget.value
    if (!url) {
      alert('URLを入力してください。')
      return
    }

    try {
      const data = await fetchOgpData(url)
      this.titleTarget.value = data.title
      this.imageTarget.innerHTML = ''
      if (data.image_base64) {
        const image = new Image()
        const extension = data.image.split('.').pop()
        const dataUrl = `data:image/${extension};base64,${data.image_base64}`
        image.src = dataUrl
        image.onload = () => {
          this.imageTarget.appendChild(image)
          this.imageBase64Target.value = dataUrl
        }
      } else {
        this.imageBase64Target.value = 'delete'
        this.imageTarget.innerHTML = `<div style='width: 240px; height: 126px; background: #D9D9D9;'></div>`
      }
    } catch (error) {
      alert('取得に失敗しました。時間をおいて再度お試しください。')
    }
  }
}
