import { Controller } from 'stimulus'
import { Toast } from 'bootstrap'
export default class extends Controller {
  static targets = ['followCheckbox']
  static values = {
    tagType: String,
    tagId: Number,
    tagWord: String,
    followed: Boolean,
    userSalt: String,
  }

  connect() {
    this.changeChecked()
  }

  check() {
    if (this.followedValue) {
      // フォロー中
      this.unfollow()
      this.followedValue = false
    } else {
      // 未フォロー
      this.follow()
      this.followedValue = true
    }
  }

  follow() {
    fetch('/api/web/user_tag_relations', {
      method: 'POST',
      credentials: 'same-origin',
      referrerPolicy: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        followable_type: this.tagTypeValue,
        followable_id: this.tagIdValue,
        user_salt: this.userSaltValue,
      }),
    })
      .then(response => {
        if (response.ok) {
          this.followedValue = true
          this.changeChecked()
          showToast(`「${this.tagWordValue}」をフォロー`)
        }
        return response.json()
      })
      .then(json => handleJsonResponse(json))
  }

  unfollow() {
    fetch('/api/web/user_tag_relations', {
      method: 'DELETE',
      credentials: 'same-origin',
      referrerPolicy: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        followable_type: this.tagTypeValue,
        followable_id: this.tagIdValue,
        user_salt: this.userSaltValue,
      }),
    })
      .then(response => {
        if (response.ok) {
          this.followedValue = false
          this.changeChecked()
          showToast(`「${this.tagWordValue}」のフォローを解除`)
        }
        return response.json()
      })
      .then(json => handleJsonResponse(json))
  }

  changeChecked() {
    if (this.followedValue) {
      // フォロー中
      this.followCheckboxTarget.checked = true
    } else {
      // 未フォロー
      this.followCheckboxTarget.checked = false
    }
  }
}

function handleJsonResponse(json) {
  switch (json.result) {
    case 'success':
      // 成功事は何もしない
      break
    case 'failed':
      window.alert(json.message)
      break
    case 'require_login': {
      const msg =
        'フォローするにはログインが必要です。\nログインページに移動しますか？'
      const result = window.confirm(msg)
      if (result) {
        window.location.href = '/u/login'
      }
      break
    }
    case 'first_follow': {
      break
    }
    default:
      window.alert('エラーが発生しました。再実行してください。')
  }
}

// トースト表示
function showToast(message) {
  const toastEl = document.getElementById('followup-toast')
  document.querySelector('#followup-toast .toast-message').textContent = message
  const toast = Toast.getOrCreateInstance(toastEl)
  toast.show()
}
