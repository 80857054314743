import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'existingRepImageWrapper',
    'repImagePreviewWrapper',
    'repImagePreview',
  ]

  static values = {
    repImageSlot: Number,
    showExistingRepImageWrapper: { type: Boolean, default: false },
    showRepImagePreviewWrapper: { type: Boolean, default: false },
  }

  existingRepImageWrapperTargetConnected() {
    this.showExistingRepImageWrapperValue = true
  }

  showRepImagePreviewWrapperValueChanged() {
    this.repImagePreviewWrapperTarget.classList.toggle(
      'd-none',
      !this.showRepImagePreviewWrapperValue,
    )
  }

  showExistingRepImageWrapperValueChanged() {
    if (!this.hasExistingRepImageWrapperTarget) return
    this.existingRepImageWrapperTarget.classList.toggle(
      'd-none',
      !this.showExistingRepImageWrapperValue,
    )
  }

  onChangeRepImageSlot(event) {
    const previousRepImageSlotValue = this.repImageSlotValue
    const repImageSlotValue = event.target.value

    // 「選択してください」（代表画像なし）を選択したとき
    if (repImageSlotValue === '') {
      this.repImagePreviewTarget.src = ''
      this.showRepImagePreviewWrapperValue = false
      this.showExistingRepImageWrapperValue = false
      return
    }

    const existingImgTag = document.getElementById(
      `slot_${repImageSlotValue}_existing`,
    )
    const previewImgTag = document.getElementById(
      `slot_${repImageSlotValue}_preview`,
    )
    const existingImageSrc = existingImgTag
      ? existingImgTag.getAttribute('src')
      : ''
    const previewImageSrc = previewImgTag
      ? previewImgTag.getAttribute('src')
      : ''
    const src = previewImageSrc || existingImageSrc

    // 画像がないスロットを選択したとき
    if (!src) {
      alert('選択されたスロットに画像がありません')
      event.target.value = previousRepImageSlotValue || ''
      return
    }

    // 画像があるスロットを選択したとき
    this.repImageSlotValue = repImageSlotValue
    this.repImagePreviewTarget.src = src
    this.showRepImagePreviewWrapperValue = true
    this.showExistingRepImageWrapperValue = false
  }
}
