import { ApplicationController } from '../../application_controller'

const STATE = {
  DRAFT: 'draft',
  AI_CHECKED: 'ai_checked',
}

export default class extends ApplicationController {
  static states = STATE
  static values = {
    state: { type: String, default: STATE.DRAFT },
    exlinkDraft: Object,
    editedStatus: {
      type: Object,
      default: {
        url: false,
        og_title: false,
      },
    },
  }

  aiChecked() {
    return this.exlinkDraftValue.status === 'ai_checked'
  }

  connect() {
    if (this.aiChecked()) {
      this.stateValue = STATE.AI_CHECKED
    }
  }

  checkUrl(event) {
    this.editedStatusValue.url =
      this.exlinkDraftValue.url !== event.target.value
    this.check()
  }

  checkOgTitle(event) {
    this.editedStatusValue.og_title =
      this.exlinkDraftValue.og_title !== event.target.value
    this.check()
  }

  check() {
    if (!this.aiChecked()) {
      return
    }
    if (Object.values(this.editedStatusValue).some(edited => edited)) {
      this.stateValue = STATE.DRAFT
    } else {
      this.stateValue = STATE.AI_CHECKED
    }
  }
}
