import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = [
    'form',
    'cardArea',
    'pointAll',
    'pointPart',
    'usePoint',
    'paymentType',
    'isFreeNotice',
  ]
  static classes = ['hidden']
  static values = { total: Number, availablePoint: Number }

  connect() {
    this.changeVisibility()
    this.changeUsePoint()
  }

  // 可視状態変更
  changeVisibility() {
    switch (this.formTarget.elements['payment'].value) {
      case 'クレジット決済':
        this.cardAreaTarget.classList.toggle(this.hiddenClass, false)
        break
      default:
        this.cardAreaTarget.classList.toggle(this.hiddenClass, true)
        break
    }
  }

  changeUsePoint() {
    if (!this.hasPointAllTarget) {
      return
    }
    this.usePointTarget.disabled = !this.pointPartTarget.checked
    if (
      (this.pointPartTarget.checked &&
        this.totalValue <= this.usePointTarget.value) ||
      (this.pointAllTarget.checked &&
        this.totalValue <= this.availablePointValue)
    ) {
      this.paymentTypeTargets.forEach(element => {
        element.disabled = true
      })
      this.isFreeNoticeTarget.classList.toggle(this.hiddenClass, false)
    } else {
      this.paymentTypeTargets.forEach(element => {
        element.disabled = false
      })
      this.isFreeNoticeTarget.classList.toggle(this.hiddenClass, true)
    }
  }
}
