import { DiffController, STATE } from './diff_controller'
import { difference, intersection } from 'lodash'

export default class extends DiffController {
  static states = STATE
  static values = {
    state: { type: String, default: STATE.ORIGINAL },
    revisions: Array,
    selected: { type: Number, default: 0 },
    highlightedLine: { type: Number, default: 0 },
  }
  static targets = [
    'title',
    'content',
    'items',
    'selector',
    'tags',
    'status',
    'examinations',
    'preview',
  ]

  select(event) {
    super.select(event)

    // プレビューの表示更新
    if (this.hasPreviewTarget) {
      this.previewTargets.forEach((t, i) => {
        t.classList.add('d-none')
        if (i === this.selectedValue) {
          t.classList.remove('d-none')
        }
      })
    }

    // ORIGINALの場合は、選択されたリビジョンの内容をそのまま表示する
    if (this.stateValue === STATE.ORIGINAL) {
      this.titleTarget.innerHTML = this.escapeHTML(
        this.revisionsValue[this.selectedValue].title,
      )
      this.contentTarget.innerHTML = this.highlightLine(
        this.replaceNewLine(
          this.escapeHTML(this.revisionsValue[this.selectedValue].content),
        ),
        this.highlightedLineValue,
      )

      this.itemsTarget.innerHTML = this.createItemsBadgeHTML(
        this.revisionsValue[this.selectedValue].items.map(i =>
          this.escapeHTML(i),
        ),
      )

      if (this.hasTagsTarget) {
        this.tagsTarget.innerHTML = this.escapeHTML(
          this.revisionsValue[this.selectedValue].article_tags
            .map(t => t.name)
            .join('、'),
        )
      }
      // DIFFの場合は、選択されたリビジョンとその一つ前のリビジョンの差分を表示する
    } else {
      const toIndex = this.selectedValue
      const fromIndex =
        this.revisionsValue.length <= toIndex + 1 ? toIndex : toIndex + 1

      const fromTitle = this.escapeHTML(this.revisionsValue[fromIndex].title)
      const toTitle = this.escapeHTML(this.revisionsValue[toIndex].title)
      this.titleTarget.innerHTML = this.createDiffHTML(fromTitle, toTitle)

      const fromContent = this.escapeHTML(
        this.revisionsValue[fromIndex].content,
      )
      const toContent = this.escapeHTML(this.revisionsValue[toIndex].content)
      this.contentTarget.innerHTML = this.createDiffHTML(fromContent, toContent)

      const fromItems = this.revisionsValue[fromIndex].items.map(i =>
        this.escapeHTML(i.item_name),
      )
      const toItems = this.revisionsValue[toIndex].items.map(i =>
        this.escapeHTML(i.item_name),
      )
      const itemIntersections = intersection(fromItems, toItems)
      const itemDiffFrom = difference(fromItems, toItems)
      const itemDiffTo = difference(toItems, fromItems)
      let html = ''
      html += this.createItemsBadgeHTML(itemIntersections)
      html += `<ins>${this.createItemsBadgeHTML(itemDiffTo)}</ins>`
      html += `<del>${this.createItemsBadgeHTML(itemDiffFrom)}</del>`
      this.itemsTarget.innerHTML = html

      if (this.hasTagsTarget) {
        const fromTags = this.revisionsValue[fromIndex].article_tags.map(t =>
          this.escapeHTML(t.name),
        )
        const toTags = this.revisionsValue[toIndex].article_tags.map(t =>
          this.escapeHTML(t.name),
        )
        const tagIntersections = intersection(fromTags, toTags)
        const tagDiffFrom = difference(fromTags, toTags)
        const tagDiffTo = difference(toTags, fromTags)
        html = [
          ...tagIntersections,
          ...tagDiffTo.map(t => `<ins>${t}</ins>`),
          ...tagDiffFrom.map(t => `<del>${t}</del>`),
        ].join('、')
        this.tagsTarget.innerHTML = html
      }
    }
  }

  highlight({ detail: { line } }) {
    this.highlightedLineValue = line
    this.select()
  }
}
