import { Controller } from 'stimulus'

/*
「詳細を開く」ボタン用。
detailTargetとbuttonTargetの .d-none と .d-block を入れ替える感覚

使用例：
div data-controller="detail-open"
  .d-none.d-md-block data-detail-open-target="detail"
    / 詳細要素の内容をここに
  button.btn.btn-primary.d-block.d-md-none data-action="click->detail-open#open" data-detail-open-target="button"
    | 詳細を開く
 */
export default class extends Controller {
  static targets = ['detail', 'button']

  open() {
    this.detailTarget.classList.remove('d-none')
    this.detailTarget.classList.add('d-block')
    this.buttonTarget.classList.remove('d-block')
    this.buttonTarget.classList.add('d-none')
  }
}
