import { Controller } from 'stimulus'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja')

export default class extends Controller {
  static targets = ['output']

  intervalId = null

  connect() {
    let target = this.outputTarget
    let now = dayjs()

    let func = function () {
      target.textContent = `${dayjs(dayjs().diff(now)).format('mm:ss')} 経過`
    }
    this.intervalId = setInterval(func, 1000)
  }

  disconnect() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
}
