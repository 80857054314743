import { Controller } from 'stimulus'
import { ApiClient } from '../utils/ApiClient'

export default class extends Controller {
  async readAll() {
    const response = await ApiClient.post(`/api/web/notification_targets/read`)
    if (response.status === 200) {
      location.reload()
    }
  }
}
