import { Controller } from 'stimulus'
import { ApiClient } from '../../../utils/ApiClient'

const AUTO_SCORE = 1
const TIME_TO_FINISH_READING = 60 * 1000
export default class extends Controller {
  static targets = ['range', 'end']
  static values = {
    articleId: Number,
    score: { type: Number, default: null },
    autoScore: { type: Number, default: AUTO_SCORE },
    timeToFinishReading: { type: Number, default: TIME_TO_FINISH_READING },
    showedRatingSection: { type: Boolean, default: false },
    spentTimeForReading: { type: Boolean, default: false },
  }

  connect() {
    if (!this.hasEndTarget) {
      return
    }
    // 読了マーク位置までスクロールされたら自動評価を行う
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.showedRatingSectionValue = true
          this.autorate()
          observer.disconnect()
        }
      })
    })
    observer.observe(this.endTarget)
    // 読了時間が経過したら自動評価を行う
    setTimeout(() => {
      this.spentTimeForReadingValue = true
      this.autorate()
    }, this.timeToFinishReadingValue)
  }

  async autorate() {
    if (!this.showedRatingSectionValue || !this.spentTimeForReadingValue) {
      return
    }
    const score_auto = this.autoScoreValue
    await ApiClient.post(
      `/api/web/echosys/articles/${this.articleIdValue}/auto_ratings`,
      {
        score_auto,
      },
    )
  }

  async rate(event) {
    try {
      const score = Number(event.target.value)
      if (score === this.scoreValue) {
        return
      }
      this.scoreValue = score
      await ApiClient.post(
        `/api/web/echosys/articles/${this.articleIdValue}/ratings`,
        {
          score,
        },
      )
    } catch (error) {
      alert(error.message)
    }
  }
}
