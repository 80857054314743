import { Controller } from 'stimulus'

/*
 *  .[data-controller='sticky-observer']
 *    .[data-sticky-observer-target='container']
 *    .sticky-bottom[data-sticky-observer-target='stickyElement']
 * の構成で、 .sticky-bottom 要素がスティッキー状態のときに
 */

export default class extends Controller {
  static targets = ['stickyElement', 'container']

  connect() {
    this.stickyElementHeight = this.stickyElementTarget.offsetHeight
    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        threshold: [0, 1],
      },
    )

    this.observer.observe(this.containerTarget)
    this.isSticky = false
    this.checkStickyState()

    window.addEventListener('scroll', this.handleScroll.bind(this))
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  disconnect() {
    this.observer.disconnect()
    window.removeEventListener('scroll', this.handleScroll.bind(this))
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  handleIntersection() {
    this.checkStickyState()
  }

  handleScroll() {
    this.checkStickyState()
  }

  handleResize() {
    this.stickyElementHeight = this.stickyElementTarget.offsetHeight
    this.checkStickyState()
  }

  checkStickyState() {
    const containerRect = this.containerTarget.getBoundingClientRect()
    const stickyRect = this.stickyElementTarget.getBoundingClientRect()
    const viewportHeight = window.innerHeight

    if (containerRect.bottom <= viewportHeight) {
      // コンテナの下端がビューポートの下端に達した（またはそれ以上スクロールした）
      this.isSticky = false
    } else if (stickyRect.top <= viewportHeight - this.stickyElementHeight) {
      // スティッキー要素の上端がビューポートの下端からスティッキー要素の高さを引いた位置以下にある
      this.isSticky = true
    } else {
      // それ以外の場合（スティッキー要素がまだ固定位置に達していない）
      this.isSticky = false
    }

    this.updateStickyState()
  }

  updateStickyState() {
    if (this.isSticky) {
      this.stickyElementTarget.classList.add('when-stuck')
    } else {
      this.stickyElementTarget.classList.remove('when-stuck')
    }
  }
}
