import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox']
  static values = {
    code: String,
  }

  onCodeClicked(event) {
    // bootstrapにより、Offcanvasが開かれる
    this.dispatch('codeClicked', { detail: { code: event.params.code } })
  }

  onCheckedChanged() {
    this.dispatch('checkedChanged', { detail: { code: this.codeValue } })
  }

  updateChecked(event) {
    this.checkboxTarget.checked = event.detail.codes.includes(this.codeValue)
  }
}
