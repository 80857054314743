import { ApiError } from './ApiClient'

export function formatCurrency(integerValue) {
  // 数字をカンマ区切りにする
  var formattedValue = integerValue.toLocaleString()
  return formattedValue
}

export function formatErrorMessage(error) {
  if (error instanceof ApiError) {
    return error.message
  }
  return '不明なエラーです。時間を置いて再度試してください。\n解決しない場合は問い合わせフォームからご連絡ください。'
}
