import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['count']
  static values = { now: Number }

  click() {
    if (isNaN(this.countValue)) {
      this.countValue = -1
    } else {
      if (this.countValue > -this.nowValue) {
        this.countValue--
      }
    }
    if (this.nowValue !== 0) {
      this.countTarget.value = this.countValue
    }
  }
  clear() {
    this.countValue = NaN
    this.countTarget.value = ''
  }
}
