import { Controller } from 'stimulus'
import { Modal } from 'bootstrap'
import { ApiClient } from '../utils/ApiClient'

export default class extends Controller {
  static targets = ['modal', 'unreadBadge']
  static values = {
    notificationTargetId: Number,
    unread: Boolean,
    open: Boolean,
  }

  connect() {
    this.modal = new Modal(this.modalTarget)
    this.modalTarget.addEventListener('show.bs.modal', () => {
      sessionStorage.setItem(this.storageKey(), this.notificationTargetIdValue)
    })
    this.modalTarget.addEventListener('hide.bs.modal', () => {
      sessionStorage.removeItem(this.storageKey())
    })
    const storedId = Number(sessionStorage.getItem(this.storageKey()))
    if (
      storedId === this.notificationTargetIdValue ||
      (!storedId && this.openValue)
    ) {
      this.modal.show()
    }
  }

  storageKey() {
    return `notification_showed_id::${location.href}`
  }

  disconnect() {
    this.modal.hide()
  }

  unreadValueChanged() {
    if (this.unreadValue) {
      this.unreadBadgeTarget.classList.remove('d-none')
    } else {
      this.unreadBadgeTarget.classList.add('d-none')
    }
  }

  async open() {
    this.modal.show()
    // 未読の場合既読にする
    if (this.unreadValue) {
      const response = await ApiClient.post(
        `/api/web/notification_targets/${this.notificationTargetIdValue}/read`,
      )
      if (response.status === 200) {
        this.unreadValue = false
      }
    }
  }

  close() {
    this.modal.hide()
  }
}
