import axios from 'axios'
import { csrfToken } from '@rails/ujs'

export const ApiClient = axios.create({
  headers: { 'X-CSRF-Token': csrfToken() },
})

export class ApiError extends Error {
  constructor(message, status) {
    super(message)
    this.status = status
  }
}

ApiClient.interceptors.response.use(
  response => response,
  error => {
    // TODO: Sentryにエラー内容を送信する
    if (error.response) {
      if (error.response.data.message) {
        throw new ApiError(error.response.data.message, error.response.status)
      }
      throw new ApiError(
        '不明なエラーです。時間を置いて再度試してください。\n解決しない場合は問い合わせフォームからご連絡ください。',
        500,
      )
    }
    throw new ApiError(
      'ネットワークエラーです。時間を置いて再度試してください。',
      500,
    )
  },
)
