import { Controller } from 'stimulus'
import $ from 'jquery'
import Turbolinks from 'turbolinks'

export default class extends Controller {
  search($event) {
    $event.preventDefault()
    // console.log('search');

    // 人数用のニセvalueを取り込み、
    const heads_min = $('#input-heads-min').val()
    const heads_max = $('#input-heads-max').val()

    // URLにシリアライズした文字列内で該当ヶ所を見付けて置き換える
    let squery = $('#form_filter').serialize()
    let a_condensed = []
    $.each(squery.split('&'), function (i, query) {
      if (query == '_hmin_=' + heads_min) {
        if (heads_min != '') {
          query = 's=n:' + heads_min
          if (heads_max != '') {
            query = query + '-' + heads_max
          }
        } else {
          if (heads_max != '') {
            query = 's=n:' + heads_max // 最少人数の代替とする
          } else {
            query = ''
          }
        }
      } else if (query == '_hmax_=' + heads_max) {
        query = ''
      }
      // パラメータがあるなら登録
      if (query.split('=')[1]) {
        a_condensed.push(query)
      }
    })
    squery = a_condensed.join('&')
    // console.log(squery);
    // return false;
    Turbolinks.visit(this.data.get('actionUrl') + '?' + squery) // 画面遷移
  }

  connect() {
    // console.log('gamelist-search connect')
    // console.log(this.data.get('actionUrl'))
  }
}
