import { Controller } from 'stimulus'
import { Modal, Toast } from 'bootstrap'
export default class extends Controller {
  static values = {
    tagType: String,
    tagId: Number,
    tagWord: String,
    tagClass: String,
    followed: Boolean,
    userSalt: String,
  }

  connect() {
    this.buttonVisibilityUpdate()
  }

  follow(event) {
    event.preventDefault()
    fetch('/api/web/user_tag_relations', {
      method: 'POST',
      credentials: 'same-origin',
      referrerPolicy: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        followable_type: this.tagTypeValue,
        followable_id: this.tagIdValue,
        user_salt: this.userSaltValue,
      }),
    })
      .then(response => {
        if (response.ok) {
          this.followedValue = true
          this.buttonVisibilityUpdate()
          showToast(`「${this.tagWordValue}」をフォロー`)
        }
        return response.json()
      })
      .then(json => handleJsonResponse(json, this.tagWordValue))
  }

  unfollow() {
    fetch('/api/web/user_tag_relations', {
      method: 'DELETE',
      credentials: 'same-origin',
      referrerPolicy: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        followable_type: this.tagTypeValue,
        followable_id: this.tagIdValue,
        user_salt: this.userSaltValue,
      }),
    })
      .then(response => {
        if (response.ok) {
          this.followedValue = false
          this.buttonVisibilityUpdate()
          showToast(`「${this.tagWordValue}」のフォローを解除`)
        }
        return response.json()
      })
      .then(json => handleJsonResponse(json, this.tagWordValue))
  }

  // ステータスに応じて表示するボタンを切り替える
  buttonVisibilityUpdate() {
    if (this.followedValue) {
      document
        .querySelectorAll(`.${this.tagClassValue} .follow`)
        .forEach(e => e.classList.add('d-none'))
      document
        .querySelectorAll(`.${this.tagClassValue} .unfollow`)
        .forEach(e => e.classList.remove('d-none'))
    } else {
      document
        .querySelectorAll(`.${this.tagClassValue} .follow`)
        .forEach(e => e.classList.remove('d-none'))
      document
        .querySelectorAll(`.${this.tagClassValue} .unfollow`)
        .forEach(e => e.classList.add('d-none'))
    }
  }
}

function handleJsonResponse(json, tagWord) {
  switch (json.result) {
    case 'success':
      // 成功時は何もしない
      break
    case 'failed':
      window.alert(json.message)
      break
    case 'require_login':
      displayRequireLoginAlertModal()
      break
    case 'first_follow':
      displayTutorialModal(`「${tagWord}」をフォローしました`)
      break
    default:
      window.alert('エラーが発生しました。再実行してください。')
  }
}

// 初回フォロー時にフォローアップ通知の説明をモーダル表示
function displayTutorialModal(message) {
  const modal = document.getElementById('followup-tutorial-modal')
  modal.querySelector('.modal-title').textContent = message
  new Modal(modal).show()
}

// ログインが必要な場合にアラートをモーダル表示
function displayRequireLoginAlertModal() {
  const modal = document.getElementById('followup-require-login-alert-modal')
  new Modal(modal).show()
}

// トースト表示
function showToast(message) {
  const toastEl = document.getElementById('followup-toast')
  document.querySelector('#followup-toast .toast-message').textContent = message
  const toast = Toast.getOrCreateInstance(toastEl)
  toast.show()
}
