import { ApplicationController } from './application_controller'

export class MultiSelectController extends ApplicationController {
  static targets = ['count', 'checkbox']
  static values = { count: Number, values: Array }

  connect() {
    this.bindUpdate = this.update.bind(this)
    this.checkboxTargets.forEach(c => {
      c.addEventListener('change', this.bindUpdate)
    })
    this.update()
  }

  disconnect() {
    this.checkboxTargets.forEach(c => {
      c.removeEventListener('change', this.bindUpdate)
    })
  }

  update() {
    const checked = this.checkboxTargets.filter(c => c.checked)
    this.countValue = checked.length
    this.valuesValue = checked.map(c => c.value)
    this.dispatch('change', { detail: { values: this.valuesValue } })
  }

  countValueChanged(count) {
    if (this.hasCountTarget) {
      this.countTarget.innerHTML = count
    }
  }
}

export default MultiSelectController
