import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button']

  connect() {
    window.addEventListener('scroll', this.toggleVisibility.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.toggleVisibility.bind(this))
  }

  toggleVisibility() {
    if (window.pageYOffset > 100) {
      this.buttonTarget.classList.add('show')
    } else {
      this.buttonTarget.classList.remove('show')
    }
  }

  rewind() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
}
