import { Controller } from 'stimulus'
import { formatCurrency } from '../../utils/format'

export default class extends Controller {
  static values = {
    subtotal: Number,
    itemPrice: Number,
    qty: Number,
    sku: String,
  }
  static targets = ['subtotal', 'discountText']

  updateDiscountText(discountAmount) {
    if (discountAmount === 0) return
    this.discountTextTarget.innerHTML = `-${formatCurrency(
      discountAmount,
    )}<span class="text-lowkey me-1">円</span>`
    this.dispatch('bgcardCodeUsed', { detail: {} })
  }

  async subtotalValueChanged() {
    this.subtotalTarget.textContent = formatCurrency(this.subtotalValue)
    this.dispatch('subtotalValueChanged', { detail: {} })
  }

  async applyBgcardCode(event) {
    if (event.detail.sku !== this.skuValue) return
    const bgcardCodes = event.detail.bgcardCodes
    const discountAmount = Math.min(
      bgcardCodes
        .map(i => i.discount_amount)
        .reduce((arr, cur) => arr + cur, 0),
      this.itemPriceValue * this.qtyValue,
    )
    const subtotal = this.itemPriceValue * this.qtyValue - discountAmount
    this.subtotalValue = subtotal
    this.updateDiscountText(discountAmount)
  }
}
