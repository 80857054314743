let stripe
let cardNumber

import { Controller } from 'stimulus'
import { Modal } from 'bootstrap'
export default class extends Controller {
  static targets = [
    'cardInputArea',
    'cardInfoArea',
    'cardInfo',
    'stripeAlert',
    'cardFragment',
    'paymentMethodId',
    'modal',
  ]
  static classes = ['hidden']

  async initialize() {
    stripe = Stripe(gon.stripe_public_key)
    this.setupElement(stripe)
  }

  connect() {
    this.changeVisibility()
  }

  // Element生成
  setupElement() {
    const elements = stripe.elements()
    const style = {
      base: {
        fontSize: '17px',
      },
    }

    // mountでDOM上に入力要素をアタッチ
    cardNumber = elements.create('cardNumber', { style: style })
    cardNumber.mount('#card-number')
    const cardExpiry = elements.create('cardExpiry', { style: style })
    cardExpiry.mount('#card-expiry')
    const cardCvc = elements.create('cardCvc', { style: style })
    cardCvc.mount('#card-cvc')
  }

  // カードエレメントの内容チェックとカード情報登録
  async checkElement() {
    // https://stripe.com/docs/js/payment_methods/create_payment_method
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
    })
    if (result.error) {
      this.showStripeAlert(result.error.message)
    } else {
      let payment_method_id = result.paymentMethod['id']
      this.attachPaymentMethodId(payment_method_id)
    }
  }

  // 支払い方法の連携
  attachPaymentMethodId(payment_method_id) {
    fetch(`/api/web/stripe_card_flagments/${payment_method_id}.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error()
        }
        return response.json()
      })
      .then(json => {
        this.cardInfoTarget.textContent = json['card_fragment']
        this.cardFragmentTarget.value = json['card_fragment']
        this.paymentMethodIdTarget.value = payment_method_id
        this.changeVisibility()
        this.closeModal()
      })
  }

  closeModal() {
    Modal.getInstance(this.modalTarget).hide()
  }

  // 認証ボタン押下
  async cardAuth(event) {
    event.preventDefault()
    this.checkElement()
  }

  // 可視状態変更
  changeVisibility() {
    // カード入力欄の表示/非表示設定
    this.cardInputAreaTarget.classList.toggle(
      this.hiddenClass,
      this.cardInfoTarget.textContent != '',
    )
    // カード情報欄の表示/非表示設定
    this.cardInfoAreaTarget.classList.toggle(
      this.hiddenClass,
      this.cardInfoTarget.textContent == '',
    )
    // Stripeアラート欄の非表示設定
    this.stripeAlertTarget.classList.add(this.hiddenClass)
  }

  showStripeAlert(messageText) {
    this.stripeAlertTarget.classList.remove(this.hiddenClass)
    this.stripeAlertTarget.textContent = messageText
    setTimeout(
      function () {
        this.stripeAlertTarget.classList.add(this.hiddenClass)
        this.stripeAlertTarget.textContent = ''
      }.bind(this),
      5000,
    )
  }
}
