import { Controller } from 'stimulus'
import { ApiClient } from '../../utils/ApiClient'

export default class extends Controller {
  static values = {
    articleDraftId: Number,
  }

  connect() {
    this.element.click()
    this.timerId = setInterval(() => {
      this.checkAi()
    }, 5000)
  }

  disconnect() {
    clearInterval(this.timerId)
  }

  async checkAi() {
    const response = await ApiClient.get(
      `/api/web/echosys/article_drafts/${this.articleDraftIdValue}/status`,
    )
    if (response.status === 200 && response.data.status != 'requested') {
      location.reload()
    }
  }
}
