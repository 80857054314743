import { Controller } from 'stimulus'
import Compressor from 'compressorjs'
import { ApiClient } from '../../utils/ApiClient'

export default class extends Controller {
  static targets = [
    'fileField',
    'existingImageWrapper',
    'existingImage',
    'imagePreviewWrapper',
    'imagePreview',
    'deleteButton',
    'deleteButtonText',
  ]

  static values = {
    articleDraftId: Number,
    slot: Number,
    showDeleteButton: { type: Boolean, default: false },
    showImagePreviewWrapper: { type: Boolean, default: false },
    showExistingImageWrapper: { type: Boolean, default: false },
  }

  static outlets = ['articles--asciidoc-editor']

  existingImageWrapperTargetConnected() {
    this.showExistingImageWrapperValue = true
    this.showDeleteButtonValue = true
  }

  showDeleteButtonValueChanged() {
    this.deleteButtonTarget.classList.toggle(
      'd-none',
      !this.showDeleteButtonValue,
    )
  }

  showImagePreviewWrapperValueChanged() {
    this.imagePreviewWrapperTarget.classList.toggle(
      'd-none',
      !this.showImagePreviewWrapperValue,
    )
  }

  showExistingImageWrapperValueChanged() {
    if (!this.hasExistingImageWrapperTarget) return
    this.existingImageWrapperTarget.classList.toggle(
      'd-none',
      !this.showExistingImageWrapperValue,
    )
  }

  preview() {
    const self = this
    const file = self.fileFieldTarget.files[0]
    if (!file) return

    new Compressor(file, {
      quality: 0.85,
      maxWidth: 1280,
      maxHeight: 960,
      convertSize: 1000000,
      async success(result) {
        const blob = window.URL.createObjectURL(result)
        const fileSizeInMB = result.size / 1024 / 1024
        if (fileSizeInMB > 8) {
          alert('画像のサイズは8MB以下にしてください')
          self.fileFieldTarget.value = ''
          return
        }
        const formData = new FormData()
        formData.append('image', result, result.name)
        try {
          await ApiClient.post(
            `/api/web/echosys/article_drafts/${self.articleDraftIdValue}/images/${self.slotValue}`,
            formData,
          )
          self.imagePreviewTarget.src = blob
          self.showImagePreviewWrapperValue = true
          const roundedFileSizeInMB = Math.round(fileSizeInMB * 100) / 100
          self.deleteButtonTextTarget.innerText = `${roundedFileSizeInMB}MB`
          self.showDeleteButtonValue = true
        } catch (error) {
          alert(error.message)
        }
      },
      error() {
        alert(
          '画像のサイズを8MB以下にするかJPEGファイルで画像を追加してみてください',
        )
      },
    })
  }

  async delete() {
    try {
      await ApiClient.delete(
        `/api/web/echosys/article_drafts/${this.articleDraftIdValue}/images/${this.slotValue}`,
      )
      if (this.hasExistingImageWrapperTarget)
        this.showExistingImageWrapperValue = false
      this.showImagePreviewWrapperValue = false
      this.showDeleteButtonValue = false
      this.fileFieldTarget.value = ''
      if (this.hasExistingImageTarget) this.existingImageTarget.src = ''
      this.imagePreviewTarget.src = ''
    } catch (error) {
      alert(error.message)
    }
  }

  insertImage() {
    this.articlesAsciidocEditorOutlet.insertImage(this.slotValue)
  }
}
