import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'radio',
    'display',
    'dest_first_name',
    'dest_last_name',
    'dest_phone',
    'dest_address1',
    'dest_address2',
    'dest_prefecture',
    'dest_zip1',
    'dest_zip2',
  ]

  connect() {
    this.changeDisplay()
  }

  radioChange() {
    this.changeDisplay()
  }

  changeDisplay() {
    if (this.radioTarget.checked) {
      this.displayTarget.classList.add('d-none')
      this.dest_last_nameTarget.disabled = true
      this.dest_first_nameTarget.disabled = true
      this.dest_phoneTarget.disabled = true
      this.dest_zip1Target.disabled = true
      this.dest_zip2Target.disabled = true
      this.dest_prefectureTarget.disabled = true
      this.dest_address1Target.disabled = true
      this.dest_address2Target.disabled = true
    } else {
      this.displayTarget.classList.remove('d-none')
      this.dest_last_nameTarget.disabled = false
      this.dest_first_nameTarget.disabled = false
      this.dest_phoneTarget.disabled = false
      this.dest_zip1Target.disabled = false
      this.dest_zip2Target.disabled = false
      this.dest_prefectureTarget.disabled = false
      this.dest_address1Target.disabled = false
      this.dest_address2Target.disabled = false
    }
  }
}
