import { ApplicationController } from '../application_controller'

const STATE = {
  DRAFT: 'draft',
  AI_CHECKED: 'ai_checked',
}

export default class extends ApplicationController {
  static states = STATE
  static values = {
    state: { type: String, default: STATE.DRAFT },
    articleDraft: Object,
    editedStatus: {
      type: Object,
      default: {
        title: false,
        content: false,
      },
    },
  }

  aiChecked() {
    return this.articleDraftValue.status === 'ai_checked'
  }

  connect() {
    if (this.aiChecked()) {
      this.stateValue = STATE.AI_CHECKED
    }
  }

  checkTitle(event) {
    this.editedStatusValue.title =
      this.articleDraftValue.title !== event.target.value
    this.check()
  }

  checkContent(event) {
    this.editedStatusValue.content =
      (this.articleDraftValue.content ?? '').replace(/\r?\n/g, '\n') !==
      event.detail.text.replace(/\r?\n/g, '\n')
    this.check()
  }

  check() {
    if (!this.aiChecked()) {
      return
    }
    if (Object.values(this.editedStatusValue).some(edited => edited)) {
      this.stateValue = STATE.DRAFT
    } else {
      this.stateValue = STATE.AI_CHECKED
    }
  }
}
