import { Controller } from 'stimulus'
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static values = {
    whitelist: Array,
  }
  static targets = ['tagify', 'tagNames']

  connect() {
    this.tagify = new Tagify(this.tagifyTarget, {
      whitelist: this.whitelistValue,
      dropdown: {
        position: 'manual',
        maxItems: Infinity,
        enabled: 0,
        classname: 'customSuggestionsList',
      },
      templates: {
        dropdownItemNoMatch() {
          return `<div class='empty'>該当なし</div>`
        },
      },
      enforceWhitelist: true,
      originalInputValueFormat: valuesArr =>
        valuesArr.map(item => item.value).join(','),
    })
    this.renderSuggestionsList()
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentElement
  renderSuggestionsList() {
    this.tagify.dropdown.show() // load the list
    this.tagify.DOM.scope.parentNode.appendChild(this.tagify.DOM.dropdown)
  }

  disconnect() {
    this.tagify.removeAllTags()
  }
}
