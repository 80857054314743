import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cause', 'header_set', 'inverse_qty', 'to_division']

  connect() {
    this.changeVisibility()
  }

  causeChange() {
    this.initChoice()
    this.changeVisibility()
  }

  initChoice() {
    switch (this.causeTarget.value) {
      case '荷送り→到着に変換':
      case '発注→納品に変換':
      case '打ち消し訂正':
        this.header_setTarget.value = 'ChangeLog形式'
        this.inverse_qtyTarget.checked = true
        break
      case '卸':
        this.inverse_qtyTarget.checked = true
        break
      default:
        this.header_setTarget.value = '[なし]'
        this.inverse_qtyTarget.checked = false
        break
    }
    document.getElementById('stock_division_none').checked = true
  }
  changeVisibility() {
    switch (this.causeTarget.value) {
      case '荷送り':
      case '即時移動':
        this.to_divisionTarget.style.visibility = 'visible'
        break
      default:
        document.getElementById('to_division_none').checked = true
        this.to_divisionTarget.style.visibility = 'hidden'
        break
    }
  }
}
