// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-bs-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'
import axios from 'axios'
import { csrfToken } from '@rails/ujs'
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken()
import $ from 'jquery'

export default class extends Controller {
  static targets = ['btnText']

  async cartClick() {
    const SKU = this.data.get('sku')
    const QTY = 1
    this.element.innerHTML = '通信中'
    this.postAndReplaceElement({ SKU, QTY })
  }

  async select_qty(event) {
    const SKU = this.data.get('sku')
    const selectDom = event.target
    const idx = selectDom.selectedIndex
    const QTY = selectDom.options[idx].value

    // console.log(QTY)

    this.postAndReplaceElement({ SKU, QTY })
  }

  async postAndReplaceElement(postData) {
    const url = '/api/web/cart_items'
    try {
      const {
        request: { responseURL },
        data: {
          qty,
          qtysum,
          basic,
          wo_lottery,
          postage_discount,
          postage_discount_wo_lottery,
        },
      } = await axios.post(url, postData)
      // セッションタイムアウト判定
      // axiosではリダイレクト時も200が返るので、responseURLで判定
      if (!responseURL.includes(url)) {
        throw new Error('session timeout and redirect to login page')
      }
      const { data } = await axios.get(this.data.get('url'), {
        params: { value: qty },
      })
      this.element.innerHTML = data

      document.getElementById('cart_qtysum').textContent = qtysum

      document.getElementById('cart_subtotal').textContent = this.cdnum(basic)

      const listicleSubtotalWOLottery = document.getElementById(
        'listicle_subtotal_wo_lottery',
      )
      if (listicleSubtotalWOLottery !== null) {
        listicleSubtotalWOLottery.textContent = this.cdnum(wo_lottery)
      }

      const listicleSubtotal = document.getElementById('listicle_subtotal')
      if (listicleSubtotal !== null) {
        listicleSubtotal.textContent = this.cdnum(basic)
      }

      const listiclePostageDiscountWOLottery = document.getElementById(
        'listicle_postage_discount_wo_lottery',
      )
      if (listiclePostageDiscountWOLottery !== null) {
        listiclePostageDiscountWOLottery.textContent = this.cdnum(
          -postage_discount_wo_lottery,
        )
      }

      const listiclePostageDiscount = document.getElementById(
        'listicle_postage_discount',
      )
      if (listiclePostageDiscount !== null) {
        listiclePostageDiscount.textContent = this.cdnum(-postage_discount)
      }

      if (0 < qtysum) {
        // カート内商品がある
        if ($('#cart_display').hasClass('d-none')) {
          // 消えているので表示
          $('#cart_display').fadeIn(100, function () {
            $(this).removeClass('d-none')
          })
        }
      } else {
        // カート内ナシ
        if (!$('#cart_display').hasClass('d-none')) {
          // 表示しているようなので消去
          $('#cart_display').fadeOut(100, function () {
            $(this).addClass('d-none')
          })
        }
      }
    } catch (err) {
      // console.log(err)
      // this.element.innerHTML = 'error:'+err
      document.location.reload()
    }
  }

  connect() {
    // console.log('stock connect')
  }

  cdnum(str) {
    let num = new String(str).replace(/,/g, '')
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, '$1,$2')));
    return num
  }
}
