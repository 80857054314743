import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['contents', 'toggleLink']
  static values = {
    fullText: String,
    truncatedText: String,
  }

  toggle() {
    if (this.toggleLinkTarget.innerText === 'もっとみる') {
      this.contentsTarget.innerText = this.fullTextValue
      this.toggleLinkTarget.innerText = 'たたむ'
    } else {
      this.contentsTarget.innerText = this.truncatedTextValue
      this.toggleLinkTarget.innerText = 'もっとみる'
    }
    // アンカーからフォーカスを外す
    this.toggleLinkTarget.blur()
  }
}
