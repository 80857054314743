import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['examination', 'style']
  static values = {
    line: { type: Number, default: null },
    selected: { type: Number, default: null },
  }

  toggleHighlight(e) {
    if (!e.params.line) {
      return
    }
    if (this.selectedValue === e.params.index) {
      this.selectedValue = null
      this.lineValue = null
      this.dispatch('highlight', { detail: { line: null } })
    } else {
      this.selectedValue = e.params.index
      this.lineValue = e.params.line
      this.dispatch('highlight', { detail: { line: e.params.line } })
    }
    this.examinationTargets.forEach((t, i) => {
      t.classList.toggle('bg-danger-subtle', i === this.selectedValue)
    })
  }
}
