import { Controller } from 'stimulus'
import { formatCurrency } from '../../utils/format'

export default class extends Controller {
  static targets = ['totalAmount', 'appliedPrice']
  static outlets = ['bgcard-codes--item']

  updateTotal() {
    const subtotal = this.bgcardCodesItemOutlets
      .map(io => io.subtotalValue)
      .reduce((a, b) => a + b, 0)
    this.totalAmountTarget.textContent = formatCurrency(subtotal)
  }

  showCouponApply() {
    this.appliedPriceTarget.classList.remove('d-none')
  }
}
