import { DiffController, STATE } from './diff_controller'
import { difference, intersection } from 'lodash'

export default class extends DiffController {
  static states = STATE
  static values = {
    state: { type: String, default: STATE.ORIGINAL },
    revisions: Array,
    selected: { type: Number, default: 0 },
  }
  static targets = [
    'url',
    'description',
    'og_title',
    'og_image',
    'item',
    'selector',
    'status',
    'examinations',
  ]

  select(event) {
    super.select(event)

    // ORIGINALの場合は、選択されたリビジョンの内容をそのまま表示する
    if (this.stateValue === STATE.ORIGINAL) {
      this.urlTarget.innerHTML = this.escapeHTML(
        this.revisionsValue[this.selectedValue].url,
      )
      this.og_titleTarget.innerHTML = this.escapeHTML(
        this.revisionsValue[this.selectedValue].og_title,
      )
      if (this.revisionsValue[this.selectedValue].og_image_url) {
        this.og_imageTarget.innerHTML = `<img src="${
          this.revisionsValue[this.selectedValue].og_image_url
        }">`
      } else {
        this.og_imageTarget.innerHTML = ''
      }
      this.descriptionTarget.innerHTML = this.replaceNewLine(
        this.escapeHTML(this.revisionsValue[this.selectedValue].description),
      )

      this.itemTarget.innerHTML = this.createItemsBadgeHTML([
        this.escapeHTML(this.revisionsValue[this.selectedValue].item.item_name),
      ])
      // DIFFの場合は、選択されたリビジョンとその一つ前のリビジョンの差分を表示する
    } else {
      const toIndex = this.selectedValue
      const fromIndex =
        this.revisionsValue.length <= toIndex + 1 ? toIndex : toIndex + 1

      const fromUrl = this.escapeHTML(this.revisionsValue[fromIndex].url)
      const toUrl = this.escapeHTML(this.revisionsValue[toIndex].url)
      this.urlTarget.innerHTML = this.createDiffHTML(fromUrl, toUrl)

      const fromOgTitle = this.escapeHTML(
        this.revisionsValue[fromIndex].og_title,
      )
      const toOgTitle = this.escapeHTML(this.revisionsValue[toIndex].og_title)
      this.og_titleTarget.innerHTML = this.createDiffHTML(
        fromOgTitle,
        toOgTitle,
      )

      this.og_imageTarget.innerHTML = ''
      if (this.revisionsValue[fromIndex].og_image_url) {
        this.og_imageTarget.innerHTML = `<img src="${this.revisionsValue[fromIndex].og_image_url}">`
      }
      if (
        this.revisionsValue[fromIndex].og_image_url !==
        this.revisionsValue[toIndex].og_image_url
      ) {
        this.og_imageTarget.innerHTML += ` -> `
        if (this.revisionsValue[toIndex].og_image_url) {
          this.og_imageTarget.innerHTML += `<img src="${this.revisionsValue[toIndex].og_image_url}">`
        }
      }

      const fromDescription = this.escapeHTML(
        this.revisionsValue[fromIndex].description,
      )
      const toDescription = this.escapeHTML(
        this.revisionsValue[toIndex].description,
      )
      this.descriptionTarget.innerHTML = this.createDiffHTML(
        fromDescription,
        toDescription,
      )

      const fromItems = [
        this.escapeHTML(this.revisionsValue[fromIndex].item.item_name),
      ]
      const toItems = [
        this.escapeHTML(this.revisionsValue[toIndex].item.item_name),
      ]
      const itemIntersections = intersection(fromItems, toItems)
      const itemDiffFrom = difference(fromItems, toItems)
      const itemDiffTo = difference(toItems, fromItems)
      let html = ''
      html += this.createItemsBadgeHTML(itemIntersections)
      html += `<ins>${this.createItemsBadgeHTML(itemDiffTo)}</ins>`
      html += `<del>${this.createItemsBadgeHTML(itemDiffFrom)}</del>`
      this.itemTarget.innerHTML = html
    }
  }
}
